export default [
  '福音会',
  '中国教徒',
  '统一教',
  '观音法门',
  '清海无上师',
  '盘古',
  '李洪志',
  '志洪李',
  '李宏志',
  '轮功',
  '法轮',
  '轮法功',
  '三去车仑',
  '氵去车仑',
  '发论工',
  '法x功',
  '法o功',
  '法0功',
  '法一轮一功',
  '轮子功',
  '车仑工力',
  '法lun',
  'fa轮',
  '法lg',
  'flg',
  'fl功',
  'falungong',
  '大法弟子',
  '大纪元',
  'dajiyuan',
  '明慧网',
  '明慧周报',
  '正见网',
  '新唐人',
  '伪火',
  '退党',
  'tuidang',
  '退dang',
  '超越红墙',
  '自fen',
  '真善忍',
  '九评',
  '9评',
  '9ping',
  '九ping',
  'jiuping',
  '藏字石',
  '集体自杀',
  '自sha',
  'zi杀',
  'suicide',
  'titor',
  '逢8必灾',
  '逢八必灾',
  '逢9必乱',
  '逢九必乱',
  '朱瑟里诺',
  '根达亚文明',
  '诺查丹玛斯',
  '人类灭亡进程表',
  '按照马雅历法',
  '推背图',
  '推bei图',
  '济世灵文',
  '诸世纪'
]
