import React from 'react'
import gabaIcon from '@/assets/images/gaba.png'
import './style.less'

type Props = unknown
interface Copyright {
  (props: Props): JSX.Element | null
}

const Copyright: Copyright = () => {
  return (
    <div className="copyright">
      <p>版权所有 © 2021 | ywx6265 All Rights Reserved</p>
      <p className="code">
        <img className="gaba-icon" src={gabaIcon} />
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002004117" rel="noreferrer" target="_blank">
          粤公网安备44030002004117号
        </a>
        <a style={{ marginLeft: '20px' }} href="https://beian.miit.gov.cn/" target="_blank">
          粤ICP备2021137071号
        </a>
      </p>
    </div>
  )
}

export default Copyright
