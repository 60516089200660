export default [
  '安眠酮',
  '代血浆',
  '普萘洛尔',
  '呋塞米',
  '西布曲明',
  'testosterone',
  '胰岛素样生长因子',
  '促红细胞生成素',
  '地西泮',
  '尼可刹米',
  '甲睾酮',
  'adrenaline',
  'erythropoietin',
  '地奈德',
  '莫达非尼',
  '氯噻嗪',
  '苯巴比妥',
  '促性腺激素',
  '泼尼松',
  '麻黄草',
  '雄烯二醇',
  '地塞米松',
  'tamoxifen',
  'strychnine',
  'androst',
  '新型毒品',
  '杜冷丁',
  '兴奋剂',
  'mdma',
  '海洛因',
  '海luo因',
  'heroin',
  'diamorphine',
  'diacetylmorphine',
  '鸦片',
  '阿芙蓉',
  '咖啡因',
  'cocain',
  '三唑仑',
  '美沙酮',
  '麻古',
  'k粉',
  '凯他敏',
  'ketamine',
  '冰毒',
  '苯丙胺',
  'cannabis',
  '大麻',
  '爱他死',
  '氯胺酮',
  'benzodiazepines',
  '甲基安非他明',
  '安非他命',
  '吗啡',
  'morphine',
  '摇头丸',
  '迷药',
  '乖乖粉',
  'narcotic',
  '麻醉药',
  '精神药品'
]
