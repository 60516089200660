export default {
  github: {
    username: '',
    token: '',
    oauth: {
      url: 'https://github.com/login/oauth/authorize',
      redirect_uri: 'https://ywx6265.com/api/client/user/oauth/github',
      client_id: 'a5a43ec744f53c998cf3',
      client_secret: 'd4c83a715f2579d50fc9334ab32a1eaa14f2ab66'
    }
  },
  google: {
    oauth: {
      url: '',
      redirect_uri: '',
      client_id: '',
      client_secret: ''
    }
  },
  qq: {
    oauth: {
      url: 'https://graph.qq.com/oauth2.0/authorize',
      redirect_uri: 'https://ywx6265.com/api/client/user/oauth/qq',
      appId: '102017057',
      appKey: '3yhEZjhT2OXmqhLp'
    }
  },
  oss: {
    accessKeyId: 'LTAI5tG5ZcMyVYsE9iJcuC7u',
    accessKeySecret: 'VFRNSA1iHUEfmyHw7z3E0wefB6d2Ja'
  },
  sms: {
    PhoneNumbers: '',
    SignName: '',
    accessKeyId: '',
    accessKeySecret: ''
  },
  sentry: {
    dsn: ''
  },
  mongodb: {
    dev: {
      db: 'blog',
      username: 'blog',
      password: 'Ywx_123456'
    },
    prod: {
      db: 'blog',
      username: 'blog',
      password: 'Ywx_123456'
    }
  },
  loginEmailWhiteList: [],
  jwtSecret: 'jwt_test',
  adminJwtSecret: 'admin_jwt_test',
  authorSecret: 'author_secret'
}
