export default [
  '口交',
  '江泽民',
  '胡锦涛',
  '温家宝',
  '习近平',
  '习仲勋',
  '贺国强',
  '贺子珍',
  '周永康',
  '李长春',
  '李德生',
  '王岐山',
  '姚依林',
  '回良玉',
  '李源潮',
  '李干成',
  '戴秉国',
  '黄镇',
  '刘延东',
  '刘瑞龙',
  '俞正声',
  '黄敬',
  '薄熙',
  '薄一波',
  '周小川',
  '周建南',
  '温云松',
  '徐明',
  '江泽慧',
  '江绵恒',
  '江绵康',
  '李小鹏',
  '李鹏',
  '李小琳',
  '朱云来',
  '朱容基',
  '让国人愤怒的第二代身份证',
  '第二代身份证',
  '文化大革命',
  '胡海峰',
  '六四',
  '反共',
  '共产党',
  '陈良宇',
  '老丁',
  '莱仕德事件',
  'fuck',
  '地下的先烈们纷纷打来电话询问',
  '李洪志',
  '大纪元',
  '真善忍',
  '新唐人',
  '肉棍',
  '淫靡',
  '淫水',
  '六四事件',
  '迷昏药',
  '迷魂药',
  '窃听器',
  '六合彩',
  '买卖枪支',
  '三唑仑',
  '麻醉药',
  '麻醉乙醚',
  '短信群发器',
  '帝国之梦',
  '毛一鲜',
  '黎阳平',
  '对日强硬',
  '出售枪支',
  '迷药',
  '摇头丸',
  '西藏天葬',
  '军长发威',
  'PK黑社会',
  '枪决女犯',
  '投毒杀人',
  '强硬发言',
  '出售假币',
  '监听王',
  '昏药',
  '侦探设备',
  '麻醉钢枪',
  '反华',
  '官商勾结',
  '升达毕业证',
  '手机复制',
  '戴海静',
  '自杀指南',
  '自杀手册',
  '张小平',
  '佳静安定片',
  '蒙汗药粉',
  '古方迷香',
  '强效失意药',
  '迷奸药',
  '透视眼镜',
  '远程偷拍',
  '自制手枪',
  '激情小电影',
  '黄色小电影',
  '天鹅之旅',
  '盘古乐队',
  '高校暴乱',
  '高校群体事件',
  '大学骚乱',
  '高校骚乱',
  '催情药',
  '拍肩神药',
  '春药',
  '身份证生成器',
  '枪决现场',
  '出售手枪',
  '麻醉枪',
  '办理证件',
  '办理文凭',
  '疆独',
  '藏独',
  '高干子弟',
  '高干子女',
  '枪支弹药',
  '血腥图片',
  '反政府',
  '禁书',
  '无界浏览器',
  '特码',
  '成人',
  '换妻',
  '共匪',
  '国民党',
  '腐败',
  '贪污',
  '自慰',
  '淫荡',
  '骚妇',
  '台独',
  '法论功',
  '李宏志',
  '胡海峰',
  '江湖淫娘',
  '骆冰淫传',
  '夫妇乐园',
  '阿里布达年代记',
  '爱神之传奇',
  '不良少女日记',
  '沧澜曲',
  '创世之子猎艳之旅',
  '东北风情熟女之惑',
  '风骚侍女',
  '海盗的悠闲生活',
  '黑天使',
  '黑星女侠',
  '混蛋神风流史',
  '狡猾的风水相师',
  '俪影蝎心',
  '秦青的幸福生活',
  '四海龙女',
  '逃亡艳旅',
  '性启蒙',
  '现代艳帝传奇',
  '星光伴我淫',
  '倚天屠龙别记',
  '淫术炼金士',
  '十景缎',
  '往事追忆录',
  '舌战法庭',
  '少妇白洁',
  '风月大陆',
  '风尘劫',
  '美少妇的哀羞',
  '阿兵哥言语录',
  '遥想当年春衫薄',
  '王子淫传',
  '神雕外传之郭襄',
  '睡着的武神',
  '少年阿宾',
  '毛主席复活',
  '蒙汗药',
  '粗口歌',
  '激情电影',
  'AV',
  '十八禁',
  'a片',
  '性虐待',
  '女优',
  '18禁',
  '激情',
  '耽美',
  '金瓶梅',
  '丝袜',
  '美腿',
  '毛片',
  '少儿不宜',
  '藏春阁',
  '玉蒲团',
  '隐形喷剂',
  '反雷达测速',
  '假币',
  '出售枪支',
  '出售手枪',
  '代开发票',
  '假钞',
  '窃听',
  '电话拦截系统',
  '探测狗',
  '手机卡复制器',
  '监听王',
  '手机跟踪定位器',
  '监听器',
  '针孔摄像',
  '监听宝',
  '三唑仑',
  '迷幻药',
  '手拍肩',
  '迷魂香',
  '摇头丸',
  '麻古',
  '胡锦涛',
  '温家宝',
  '共狗',
  '政治风波',
  '陈良宇',
  '反政府',
  '政府软弱',
  '政府无能',
  '九评',
  '十七大代表',
  '办证',
  '暴力拆迁',
  '轮暴致死',
  '人民报',
  '天葬',
  '轮奸',
  '暴力镇压',
  '陈水扁',
  '达赖',
  '共匪',
  '天安门事件',
  '高干子弟名单',
  '性免费电影',
  '全裸',
  '偷拍',
  '偷拍',
  '台独',
  '中共十七大'
]
